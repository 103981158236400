<template>
    <div>
        <div class="row">

            <div class="col-12 col-md-2">
                <q-list bordered padding>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="selezione_rapida"
                                @click.native="onSelezionaCheck()"
                            />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Seleziona/Deseleziona tutto</q-item-label>
                            <q-item-label caption>

                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-separator spaced />
                    <!--<q-item-label header>General</q-item-label> //-->

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.attesa_lavorazione"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>In attesa di lavorazione</q-item-label>
                            <q-item-label caption>
                                Pratiche appena richieste ma ancora non prese in carico
                            </q-item-label>
                        </q-item-section>
                    </q-item>

            <!--        <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.in_attesa_di_consegna"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>In attesa di consegna</q-item-label>
                            <q-item-label caption>
                                Pratiche lavorate ma ancora non scaricate dalla persona operativa
                            </q-item-label>
                        </q-item-section>
                    </q-item> //-->

                <!--    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.prese_in_carico"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Prese in carico</q-item-label>
                            <q-item-label caption>
                                Pratiche richieste prese in carico per essere lavorate
                            </q-item-label>
                        </q-item-section>
                    </q-item> //-->

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.in_lavorazione"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>In lavorazione</q-item-label>
                            <q-item-label caption>
                                Pratiche prese in carico e in fase di emissione
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                <!--    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.richiesta_intervento"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Richiesta di intervento</q-item-label>
                            <q-item-label caption>
                                Pratiche su cui sono stati riscontrati errori e
                                su cui è richiesto l'intervento della persona operativa
                            </q-item-label>
                        </q-item-section>
                    </q-item> //-->

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.attesa_intervento_ufficio"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Attesa intervento Ufficio</q-item-label>
                            <q-item-label caption>
                                Pratiche su cui sono stati riscontrati errori e
                                su cui è richiesto l'intervento dell'Ufficio
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.attesa_intervento_backoffice"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Attesa intervento BackOffice</q-item-label>
                            <q-item-label caption>
                                Pratiche su cui sono stati riscontrati errori e
                                su cui è richiesto l'intervento del BackOffice
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.definite"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Definite</q-item-label>
                            <q-item-label caption>
                                Pratiche attive
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                    <q-item tag="label" v-ripple>
                        <q-item-section side top>
                            <q-checkbox v-model="filtro_ricerca.annullate"
                                @click.native="onAggiornaFiltroTabella()" />
                        </q-item-section>

                        <q-item-section>
                            <q-item-label>Annullate</q-item-label>
                            <q-item-label caption>
                                Pratiche annullate o disdettate
                            </q-item-label>
                        </q-item-section>
                    </q-item>

                </q-list>
            </div>

            <div class="col-12 col-md-10 q-px-md">
                <div align="center">
                    <QQButton class="q-mb-sm"
                                    label="Aggiorna elenco"
                                    color="blue-grey"
                                    icon="mdi-database-refresh-outline"
                                    size="sm"
                                    @click.native="aggiornaListaPratiche"
                                />
                </div>
                <hr>
                <q-table
                    title="Clicca su una pratica per attivare le opzioni di gestione"
                    :data="righe"
                    wrap-cells
                    :columns="colonne"
                    :filter="filter"
                    separator="cell"
                    row-key="numero_interno"
                    @row-click="selezionaRecordPratiche"
                    :rows-per-page-options="[20,40,60,80]"
                    class="my-sticky-header-table no-box-shadow q-mt-none">

                    <template v-slot:top-right>
                        <div class="panel-cerca">
                            <q-input outlined dense debounce="300" v-model="filter" placeholder="Cerca" style="min-width: 350px;">
                                <template v-slot:append>
                                    <q-icon name="search" />
                                </template>
                            </q-input>
                        </div>
                    </template>

                    <template v-slot:header="props">
                        <q-tr :props="props">
                            <q-th
                                v-for="col in props.cols"
                                :key="col.name"
                                :props="props"
                                style="font-size: 14px; border-bottom: 1px SOLID #404040; background-color: #ffcc99; color: #000;">
                                <strong>{{ col.label }}</strong>
                            </q-th>
                        </q-tr>
                    </template>

                    <template v-slot:body="props">
                        <q-tr :props="props" style="cursor: pointer;"
                            @click.native="selezionaRecordPratiche(null,props.row)">
                            <q-td auto-width>
                                {{ props.cols[0].value }}
                            </q-td>
                        <!--    <q-td auto-width>
                                {{ props.cols[1].value }}
                            </q-td> //-->
                            <q-td auto-width>
                                {{ props.cols[1].value }}
                            </q-td>
                            <q-td auto-width>
                                {{ props.cols[2].value }}
                            </q-td>
                            <q-td auto-width>
                                {{ props.cols[3].value }}
                            </q-td>
                            <q-td auto-width>
                                {{ props.cols[4].value }}
                            </q-td>
                            <q-td auto-width v-html="props.cols[5].value">
                            </q-td>
                        </q-tr>
                    </template>

                </q-table>

            </div>
            <hr>
        </div>

    <!--    <div class="text-center justify-center q-pa-md q-gutter-sm fixed-center"
            v-show="!is_loading"
            style="z-index: 1000;">

            <q-spinner-hourglass
                size="4em"
            />

        </div> //-->

        <q-dialog v-model="persistent" persistent transition-show="scale" transition-hide="scale">
            <q-card class="bg-teal text-white shadow" style="width: 500px;">
                <q-card-section>
                    <div class="text-h6">Seleziona comando</div>
                </q-card-section>

                <q-card-section class="q-pt-none">
                    <div class="row justify-center">
                        <QQButton v-if="isPulsanteComandiVisible('IN ATTESA DI LAVORAZIONE')" label="Prendi in carico" color="blue-grey" icon="mdi-pencil-ruler" size="sm" @click.native="onSelezionaPratica"/>
                    </div>
                </q-card-section>

                <q-card-actions align="right" class="bg-white text-teal">
                    <q-btn flat label="ANNULLA" v-close-popup />
                </q-card-actions>
            </q-card>
        </q-dialog>
        <br><br><br><br><br><br>
    </div>
</template>

<script>

// Include la libreria con le funzioni comuni a tutti i componenti
import QQButton from "@/components/QQButton.vue";
import policy from "@/libs/policy.js";
import { mapState, mapActions } from "vuex";
import {mapFields} from "vuex-map-fields"

export default {
    name: "TabellaPreventivi",
    components: {
        QQButton
    },
    data() {
        return {
            filter: '',
            persistent: false,
            TipoRecordSelezionato: "",
            checked: false,
            filtro_ricerca: {
                attesa_lavorazione: true,
                attesa_intervento_ufficio: true,
                attesa_intervento_backoffice: false,
                in_lavorazione: true,
                in_attesa_di_consegna: true,
                definite: false,
                annullate: false
            },
            righe: this.rows,
            selezione_rapida: null
        }
    },
    methods: {
        VisualizzaDatiProdotto(value) {
            value = value.replace("-","<br> - ");
            var elements = value.split(":");

            // return "<strong>"+elements[0]+": </strong>"+elements[1];
            if (elements[0] !== "TOTALE") {
                return elements[0]+": <strong>"+elements[1]+"</strong>";
            }

            return "-------------------------------------------<br><div align='center'>----> "+
                   elements[0]+": <strong>"+
                   elements[1]+"</strong> <----</div>";
        },
        inserisciFiltroTabella() {
             this.righe = this.rows.filter(campo => {
                let stato = campo.stato;
                switch(stato.toUpperCase()) {
                    case "IN ATTESA DI LAVORAZIONE":
                        return this.filtro_ricerca.attesa_lavorazione;
                    //case "PRESA IN CARICO":
                    //    return this.filtro_ricerca.prese_in_carico;
                    case "IN LAVORAZIONE":
                        return this.filtro_ricerca.in_lavorazione;
                    //case "RICHIESTA DI INTERVENTO":
                    //    return this.filtro_ricerca.richiesta_intervento;
                    case "IN ATTESA DI CONSEGNA":
                        return this.filtro_ricerca.in_attesa_di_consegna;
                    case "DEFINITA":
                        return this.filtro_ricerca.definite;
                    case "ATTESA INTERVENTO UFFICIO":
                        return this.filtro_ricerca.attesa_intervento_ufficio;
                    case "ATTESA INTERVENTO BACKOFFICE":
                        return this.filtro_ricerca.attesa_intervento_backoffice;
                    case "ANNULLATA":
                    case "CANCELLATA":
                        return this.filtro_ricerca.annullate;
                }
            });
        },
        onSelezionaCheck() {
            this.filtro_ricerca.attesa_lavorazione = this.selezione_rapida;
            this.filtro_ricerca.in_lavorazione = this.selezione_rapida;
            this.filtro_ricerca.definite = this.selezione_rapida;
            this.filtro_ricerca.annullate = this.selezione_rapida;
            this.filtro_ricerca.in_attesa_di_consegna = this.selezione_rapida;
            this.filtro_ricerca.attesa_intervento_ufficio = this.selezione_rapida;
            this.filtro_ricerca.attesa_intervento_backoffice = this.selezione_rapida;

            this.inserisciFiltroTabella();
        },
        async aggiornaListaPratiche() {
            await this.fetchPratiche();
            this.onAggiornaFiltroTabella();
        },
        onAggiornaFiltroTabella() {
            this.selezione_rapida = null;
            this.inserisciFiltroTabella();
        },
    /*    async onSelezionaPratica() {
            this.persistent = false;
            let numero_pratica = this.RecordSelezionato.numero;
            await this.fetchDatiPratica(numero_pratica);

            this.$router.push({"path" : "/GestionePratiche/SchedaPratica"});
        },*/
        ...mapActions({
            fetchPratiche: "gestionePratiche/fetchPratiche",
            fetchDatiPratica: "gestionePratiche/fetchDatiPratica",
            fetchElencoDocumentiDaPreventivo: "gestioneDocumenti/fetchElencoDocumentiDaPreventivo"
        }),
        isPraticaModificabile() {
            if (policy.PuoModificarePraticheProprie()) {
                if (parseInt(policy.IdUnitaOperativa()) !== parseInt(this.RecordSelezionato["id_unita_operativa"])) {
                    return false
                }
            }
            if (policy.PuoModificarePraticheGruppo()) {
                var UnitaOperativeSottostanti = policy.UnitaOperativeSottostanti();
                // if (!UnitaOperativeSottostanti.includes(parseInt(this.RecordSelezionato["id_unita_operativa"]))) {
                if (!UnitaOperativeSottostanti.includes(this.RecordSelezionato["id_unita_operativa"])) {
                    return false;
                }
            }
            return policy.PuoModificarePraticheDiTutti() ||
                   policy.PuoModificarePraticheProprie() ||
                   policy.PuoModificarePraticheGruppo();
        },
        async selezionaRecordPratiche(event,row) {
            console.log("event:",event);
            this.TipoRecordSelezionato = row.stato;
            this.RecordSelezionato = row;

            //console.log("recordselezionato:",this.RecordSelezionato);

            // Verifica le policy prima di proseguire
        //    if (this.isPraticaModificabile()) {
                let numero_pratica = row.numero_interno;

                await this.fetchElencoDocumentiDaPreventivo(numero_pratica);
                await this.fetchDatiPratica({ numero_pratica: numero_pratica, token: "" });

                this.$router.push({name : "Pratiche.MenuGestionePratiche"});
        //    } else {
        //        this.$q.dialog({
        //            title: 'Attenzione',
        //            message: 'La pratica selezionata non può essere modificata con le tue credenziali di accesso. '
        //        });
        //    }
        },
        isPulsanteComandiVisible(Condizione) {
            return (Condizione === this.TipoRecordSelezionato)
        },

    },
    computed: {
        ...mapFields("gestionePratiche", {
            RecordSelezionato: "RecordSelezionato",
        }),
        urlFormPreventivo() {
            return "/GestionePreventivi/SelezioneArea";
        },
        ...mapState({
            Area: state => state.area,
            area: state => state.area,
            titolo_area: state => state.titolo_area,
            colonne: state => state.gestionePratiche.colonne,
            rows: state => state.gestionePratiche.righe,
            is_loading: state => state.gestionePratiche.is_loading
            //formPratica: state => state.gestionePratiche.formPratica
        }),
    },
    updated() {
    },
    destroyed() {
    },
    async mounted() {
        this.righe = this.rows;
        // per il momento forza poi leviamo
        this.$store.commit("setArea","professionisti");

        let oJSon = await this.fetchPratiche();

        this.onAggiornaFiltroTabella();

        if (this.have_errors) {
            this.$q.dialog({
                title: 'Attenzione',
                message: 'Si è verificato un errore durante l`elaborazione dei dati: '+oJSon.response.status + " - "+oJSon.response.statusText
            });
        }

        //var numero_pratica = this.formPratica.preventivo.id;
        //await this.fetchDatiPratica({ numero_pratica: numero_pratica, token: "" });
    },
    async activated() {
        //var numero_pratica = this.formPratica.preventivo.id;
        //await this.fetchDatiPratica({ numero_pratica: numero_pratica, token: "" });
        await this.aggiornaListaPratiche();
    }
}
</script>

<style scoped lang="scss">

/*    .q-td {
        vertical-align: text-top;
    }*/

</style>
